<template>
  <div class="container">
    <a-button
      @click="$router.back()"
      style="border-radius: 20px; background-image: linear-gradient(to right, #1D2671 , #830051); color: white;margin-bottom: 10px;"
      ><LeftOutlined /> Back</a-button
    >
    <div class="doctor-prescription p-5">
      <h1 class="title mb-5">UNGGAH BUKTI KWITANSI OBAT</h1>
      <!-- Added form for detailed information -->
      <a-form :model="profileData">
        <div class="row">
          <div class="col-12 text-left "><label>Nama Dokter :</label></div>
          <div class="col-12"><a-input v-model:value="profileData.namaDokter" /></div>
        </div>
        <div class="row mt-4">
          <div class="col-12 text-left"><label>Jenis Terapi :</label></div>
          <div class="col-12"><a-input v-model:value="profileData.jenisTerapi" /></div>
        </div>
        <div class="row mt-4">
          <div class="col-12 text-left"><label>Unggah Kwitansi Obat :</label></div>
          <div class="col-12">
            <p class="text-left m-0 mb-3">
              Pastikan anda mengunggah foto kwitansi secara lengkap dan jelas
            </p>
            <a-upload
              class="upload-section"
              list-type="picture-card"
              :file-list="fileList"
              @change="handleChange"
              :before-upload="() => false"
            >
              <div>
                <a-icon type="camera" />
                <div>Unggah Foto</div>
              </div>
            </a-upload>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 text-left">
            <a-button type="primary" @click="handleSubmit">KIRIM</a-button>
          </div>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { Upload, Form, Input, Button } from 'ant-design-vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import {
  getPatientProgram,
  uploadKwitansiImage,
  uploadKwitansiResult,
} from '../../services/axios/pulih'
import {
  CheckOutlined,
  ToolOutlined,
  PhoneOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  InfoCircleOutlined,
  HomeOutlined,
  ContactsOutlined,
  LeftOutlined,
} from '@ant-design/icons-vue'
export default {
  components: {
    'a-upload': Upload,
    'a-form': Form,
    'a-input': Input,
    'a-button': Button,
    LeftOutlined,
  },
  setup() {
    const fileList = ref([])
    const store = useStore()
    const profileData = ref({
      namaDokter: 'John Doe',
      jenisTerapi: 'LYNPARZA',
      email: 'johndoe@example.com',
    })
    const route = useRoute()
    const router = useRouter()
    const handleChange = ({ fileList: newFileList }) => {
      fileList.value = newFileList
    }
    const dataProgramPatient = ref()
    const handleSubmit = async () => {
      const attribute = 'unggahResepDokter'
      const value = 'unggahResepDokter'
      store.dispatch('updateAttribute', { attribute, value })
      console.log('Submitting files:', fileList.value)
      if (fileList.value.length === 0) {
        console.error('No file selected for upload.')
        return
      }

      const file = fileList.value[0].originFileObj // Ambil file yang akan diupload
      try {
        const res = await uploadKwitansiImage('Lab Test Result', file)
        console.log('Upload response:', res)
        const response = await uploadKwitansiResult(
          dataProgramPatient.value.programs[0].id,
          res.data,
        )
        console.log(response, 'inihasiltesLabTypeId')
        if (response) {
          router.push(`/dashboard`)
        }
        // Redirect atau logika tambahan setelah upload sukses
      } catch (error) {
        console.error('Upload failed:', error)
      }
    }
    onMounted(async () => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const res = await getPatientProgram(userData.user.code)
      dataProgramPatient.value = res
      profileData.value.namaDokter = res.programs[0].doctor.fullname
      profileData.value.jenisTerapi = res.programs[0].programType.name
      console.log(res, 'inidataprogramdiresep')
    })

    return {
      fileList,
      handleChange,
      handleSubmit,
      profileData,
    }
  },
}
</script>

<style scoped>
.doctor-prescription {
  /* max-width: 400px; */
  margin: auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: white;
  text-align: center;
}

.doctor-info div {
  margin-bottom: 10px;
  text-align: left;
}

.upload-section {
  /* margin: 20px 0; */
}

.title {
  color: #333;
}

.label-col {
  text-align: right;
  padding-right: 15px;
}
</style>
